import http from '@/http-common';

export interface GatheredName {
  id: string;
  name: string;
}

export class GatheredNameService {
  async getAll(projectId: string): Promise<GatheredName[]> {
    const result = await http.get(`/projects/${projectId}/gathered-names`);
    return result.data;
  }

  async delete(id: string): Promise<void> {
    return http.delete(`/gathered-names/${id}`);
  }
}

export const gatheredNameService = new GatheredNameService();
