
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EzProjectSelector from '@/components/projectSelector.vue';
import { GatheredName, gatheredNameService } from '@/services/gatheredName.service';

@Options({
  components: {
    EzProjectSelector,
  },
})
export default class LearnNamesPage extends Vue {
  @Prop({ isRequired: false }) projectId?: string;

  gatheredNames: GatheredName[] | null = null;

  onDeleteWord(word: string): void {
    console.log(word);
  }

  async created(): Promise<void> {
    if (!this.projectId) {
      return;
    }
    this.gatheredNames = await gatheredNameService.getAll(this.projectId);
  }

  async onProjectChange(projectId: string): Promise<void> {
    this.$router.push(`/learn-names/${projectId}`);
    this.gatheredNames = await gatheredNameService.getAll(projectId);
  }
}
