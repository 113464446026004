<template>
  <EzProjectSelector :initialValue="projectId" @selected="onProjectChange"></EzProjectSelector>

  <div class="content" v-if="projectId">
    <div class="columns">
    <div class="column is-half">
      <section class="names">
        <h3>Gathered names</h3>
        <div>
          <span class="tag is-medium mb-2 mr-2" v-for="name in gatheredNames" v-bind:key="name.id">
            {{name.name}}
          </span>
        </div>
      </section>
    </div>
  </div>
</div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import EzProjectSelector from '@/components/projectSelector.vue';
import { GatheredName, gatheredNameService } from '@/services/gatheredName.service';

@Options({
  components: {
    EzProjectSelector,
  },
})
export default class LearnNamesPage extends Vue {
  @Prop({ isRequired: false }) projectId?: string;

  gatheredNames: GatheredName[] | null = null;

  onDeleteWord(word: string): void {
    console.log(word);
  }

  async created(): Promise<void> {
    if (!this.projectId) {
      return;
    }
    this.gatheredNames = await gatheredNameService.getAll(this.projectId);
  }

  async onProjectChange(projectId: string): Promise<void> {
    this.$router.push(`/learn-names/${projectId}`);
    this.gatheredNames = await gatheredNameService.getAll(projectId);
  }
}
</script>
